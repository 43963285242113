import Vue from 'vue'
import Router from 'vue-router'
import httpServ from '../services/HTTPService'

Vue.use(Router)

export const routes = [{
  //FarmersRegistration
  path: '/farmersRegistration',
  component: () => import(/* webpackChunkName: "layout-default" */ '@/layouts/DefaultLayout.vue'),
  children: [{
    path: '',
    name: 'farmersRegistration',
    component: () => import(/* webpackChunkName: "starter-page" */ '@/pages/FarmersRegistration.vue')
  }]
}
,
{
  path: '/farmersVerifier',
  component: () => import(/* webpackChunkName: "layout-default" */ '@/layouts/DefaultLayout.vue'),
  children: [{
    path: '',
    name: 'farmersVerifier',
    component: () => import(/* webpackChunkName: "starter-page" */ '@/pages/FarmersVerifier.vue')
  }]
},
{
  path: '/summary',
  component: () => import(/* webpackChunkName: "layout-default" */ '@/layouts/DefaultLayout.vue'),
  children: [{
    path: '',
    name: 'summary',
    component: () => import(/* webpackChunkName: "starter-page" */ '@/pages/Summary.vue')
  }]
},
{
  path: '/cooperativeRegistration',
  component: () => import(/* webpackChunkName: "layout-default" */ '@/layouts/DefaultLayout.vue'),
  children: [{
    path: '',
    name: 'cooperativeRegistration',
    component: () => import(/* webpackChunkName: "starter-page" */ '@/pages/CooperativeRegistration.vue')
  }]
},
{
  path: '/picture',
  component: () => import(/* webpackChunkName: "layout-default" */ '@/layouts/DefaultLayout.vue'),
  children: [{
    path: '',
    name: 'picture',
    component: () => import(/* webpackChunkName: "starter-page" */ '@/pages/Picture.vue')
  }]
},
{
  //Original
  path: '/',
  component: () => import(/* webpackChunkName: "layout-default" */ '@/layouts/DefaultLayout.vue'),
  children: [{
    path: '',
    name: 'signin',
    component: () => import(/* webpackChunkName: "starter-page" */ '@/pages/SignIn.vue')
  }]
},{
  path: '/dashboard',
  component: () => import(/* webpackChunkName: "layout-default" */ '@/layouts/DefaultLayout.vue'),
  children: [{
    path: '',
    name: 'dashboard',
    component: () => import(/* webpackChunkName: "starter-page" */ '@/pages/Dashboard.vue')
  }]
},
{
  path: '/dial',
  component: () => import(/* webpackChunkName: "layout-default" */ '@/layouts/DefaultLayout.vue'),
  children: [{
    path: '',
    name: 'dial',
    component: () => import(/* webpackChunkName: "starter-page" */ '@/pages/Dial.vue')
  }]
},
///////////////////////////////////////////////

///////////////////////////////////////////////
{
  path: '*',
  component: () => import(/* webpackChunkName: "layout-error" */ '@/layouts/ErrorLayout.vue'),
  children: [{
    path: '',
    name: 'error',
    component: () => import(/* webpackChunkName: "error" */ '@/pages/error/NotFoundPage.vue')
  }]
}]

const router = new Router({
  mode: 'history',
  base: process.env.BASE_URL || '/',
  scrollBehavior(to, from, savedPosition) {
    return { x: 0, y: 0 }
  },
  routes
})

/**
 * Before each route update
 */
/*router.beforeEach((to, from, next) => {
  return next()
})*/
router.beforeEach(async (to,from,next) => {
  const data = await httpServ.getSessionJson('memberData')
  const haveAccess = data !== null ? true : false
  const publicPages = ['signin','logout','dail','register']
  const authRequired = !publicPages.includes(to.name)

  if (authRequired) {
    if (!haveAccess) {
      return next('/')
    }

    return next()
  } else if (to.name === 'logout') {
    httpServ.clearSession()
    window.location.href = 'https://nfmp.gov.ng'

    return false
  } else {
    return next()
  }

  //return false
})

/*router.beforeEach((to, from, next) => {
  const memberData = httpServ.getSessionJson('memberData')

  if (to.name === 'signin' || to.name === 'dummyDashboard') {
    if (memberData !== null) {
      next()

      return
    }
    next('/signin')

    return
  } else {
    next()

    return
  }
})*/

/**
 * After each route update
 */
router.afterEach((to, from) => {
})

export default router
